import { useEffect, useState } from "react";
import "./App.css";
import { data } from "./data.js";

function App() {
  const [album, setAlbum] = useState(null);

  useEffect(() => {
    if (!album) {
      const targetAlbum = data[Math.floor(data.length * Math.random())];
      setAlbum(targetAlbum);
    }
  }, [album]);

  if (!album) {
    return (
      <div className="App">
        <header className="App-header">Loading…</header>
      </div>
    );
  }

  // eslint-disable-next-line no-unused-vars
  const [artist, albumTitle, spotify, image] = album;

  return (
    <div className="App">
      <div className="meta">
        {decodeURIComponent(artist)} — {decodeURIComponent(albumTitle)}
        <br />
        <a
          className="spotify"
          href={`https://open.spotify.com/album/${spotify}`}
        >
          spotify →
        </a>
      </div>
      <div className="image">
        <img
          src={image.replace("300x300", "500x500")}
          alt={`Album cover for ${decodeURIComponent(
            artist
          )} by ${decodeURIComponent(albumTitle)}`}
        />
      </div>
    </div>
  );
}

export default App;
